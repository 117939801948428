import React from "react";
import arrow from "../../Assets/Icons/arrow_down.svg";
import "./styles.scss";

function HeroJet() {
	return (
		<div className="heroJet-container">
			<div>
				<h1 className="hero-title">
					Your <span>acquisition</span> journey starts here
				</h1>
			</div>
			<div className="hero-scroll-down">
				<h2>Scroll down</h2>
				<img src={arrow} alt={arrow} />
			</div>
		</div>
	);
}

export default HeroJet;
