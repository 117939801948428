import React from "react";
import Navbar from "../../Sections/NavBar";
import "../../../src/App.css";
import DigitalTwin from "../../Sections/SlideShow/DigitalTwin";
import HeroJet from "../../Sections/HeroJet";
import Lab from "../../Sections/Lab";
import Intro from "../../Sections/Intro";
import GeneticFooter from "../../Sections/genneticFooter";
import BottomBar from "../../Sections/bottomfotter";
import About from "../../Sections/About";
import FindUs from "../../Sections/FindUs";
import Services from "../../Sections/Services";
import Inventory from "../../Sections/Inventory";

function Home() {
	return (
		<div className="App">
			<Navbar />
			<div id="hero" className="Hero-outter-container">
				<HeroJet />
			</div>
			<div className="intro-outter-container">
				<Intro />
			</div>

			<div id="about" className="about-outter-container">
				<About />
			</div>

			<div id="contact" className="findUs-outter-container">
				<FindUs />
			</div>
			<div id="services" className="services-outter-container">
				<Services />
			</div>
			<div className="lab-outter-container">
				<Lab />
			</div>
			<div id="inventory" className="inventory-outter-container">
				<Inventory />
			</div>
			<div className="footer-outter-container">
				<GeneticFooter />
				<BottomBar />
			</div>
		</div>
	);
}
export default Home;
