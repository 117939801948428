import "./styles.scss";
import WaitingImg from "../../Assets/florida.jpg";
import { Link } from "react-router-dom";

function FindUs() {
	return (
		<div className="Bitoutne-Embark">
			<div className="Embark-conatiner-left">
				<div className="embark-conatiner-left-items">
					<div className="waiting-text">Where to find us?</div>
					<div className="embark-text">
						<h1>
							We are located in
							<span> Florida</span>, give us a call and let's set a tour.
						</h1>
					</div>
				</div>
				<div className="join-left-button">
					<Link
						onClick={() => {
							window.location.href = "mailto:sales@flyjetbrothers.com";
						}}
						className="left-btn-box"
					>
						Contact Us
					</Link>
				</div>
			</div>
			<div className="Embark-conatiner-right">
				<img src={WaitingImg} alt="img" />
			</div>
		</div>
	);
}

export default FindUs;
