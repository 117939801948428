import React, { useState, useEffect } from "react";
import { storage, db } from "./firebase";
import {
	ref,
	uploadBytes,
	getDownloadURL,
	deleteObject,
} from "firebase/storage";
import {
	collection,
	addDoc,
	getDocs,
	doc,
	deleteDoc,
	updateDoc,
} from "firebase/firestore";
import "./PlaneUpload.css"; // Import the CSS file

const PlaneUpload = () => {
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [sold, setSold] = useState(false);
	const [image, setImage] = useState(null);
	const [pdfDocument, setPdfDocument] = useState(null);
	const [uploading, setUploading] = useState(false);

	const [planes, setPlanes] = useState([]);
	const [loading, setLoading] = useState(true);

	const [editingPlane, setEditingPlane] = useState(null); // New state for editing

	// Function to handle uploading new plane details or updating existing ones
	const handleUpload = async () => {
		if (!name.trim()) {
			alert("Name is required!");
			return;
		}
		if (!description.trim()) {
			alert("Description is required!");
			return;
		}

		setUploading(true);

		try {
			let imageUrl = editingPlane?.imageLink || null;
			let pdfUrl = editingPlane?.pdfDocument || null;

			// Upload new image if selected
			if (image) {
				const imageRef = ref(storage, `planes/${name}/image`);
				await uploadBytes(imageRef, image);
				imageUrl = await getDownloadURL(imageRef);
			}

			// Upload new PDF document if selected
			if (pdfDocument) {
				const pdfRef = ref(storage, `planes/${name}/document`);
				await uploadBytes(pdfRef, pdfDocument);
				pdfUrl = await getDownloadURL(pdfRef);
			}

			if (editingPlane) {
				// Update existing plane details
				await updateDoc(doc(db, "planes", editingPlane.id), {
					name,
					description,
					sold,
					imageLink: imageUrl,
					pdfDocument: pdfUrl,
				});
				alert("Plane updated successfully!");
			} else {
				// Save new plane in Firestore
				await addDoc(collection(db, "planes"), {
					name,
					description,
					sold,
					imageLink: imageUrl,
					pdfDocument: pdfUrl,
				});
				alert("Upload successful!");
			}

			// Clear the form fields
			resetForm();
		} catch (error) {
			console.error("Error uploading:", error);
			alert("Upload failed. Please try again.");
		} finally {
			setUploading(false);
		}
	};

	// Function to fetch all planes from Firestore
	const fetchPlanes = async () => {
		try {
			const querySnapshot = await getDocs(collection(db, "planes"));
			const planesList = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));
			setPlanes(planesList);
		} catch (error) {
			console.error("Error fetching planes:", error);
		} finally {
			setLoading(false);
		}
	};

	// Function to delete a plane from Firestore and Firebase Storage
	const handleDelete = async (plane) => {
		if (window.confirm("Are you sure you want to delete this plane?")) {
			try {
				// Delete image from Firebase Storage
				const imageRef = ref(storage, `planes/${plane.name}/image`);
				await deleteObject(imageRef);

				// Delete PDF document from Firebase Storage
				const pdfRef = ref(storage, `planes/${plane.name}/document`);
				await deleteObject(pdfRef);

				// Delete the document from Firestore
				await deleteDoc(doc(db, "planes", plane.id));

				alert("Plane deleted successfully!");

				// Refresh the list of planes
				fetchPlanes();
			} catch (error) {
				console.error("Error deleting plane:", error);
				alert("Delete failed. Please try again.");
			}
		}
	};

	// Function to mark a plane as sold
	const handleMarkAsSold = async (plane) => {
		try {
			await updateDoc(doc(db, "planes", plane.id), {
				sold: true,
			});

			alert("Plane marked as sold!");

			// Refresh the list of planes
			fetchPlanes();
		} catch (error) {
			console.error("Error marking plane as sold:", error);
			alert("Failed to mark as sold. Please try again.");
		}
	};

	// Function to handle editing plane details
	const handleEdit = (plane) => {
		// Scroll to the top when update starts
		window.scrollTo({ top: 0, behavior: "smooth" });

		setName(plane.name);
		setDescription(plane.description);
		setSold(plane.sold);
		setImage(null); // New image upload optional
		setPdfDocument(null); // New PDF upload optional
		setEditingPlane(plane); // Set the plane being edited
	};

	// Function to reset the form and cancel the update
	const resetForm = () => {
		setName("");
		setDescription("");
		setSold(false);
		setImage(null);
		setPdfDocument(null);
		setEditingPlane(null); // Clear editing state
	};

	useEffect(() => {
		fetchPlanes();
	}, []);

	if (loading) {
		return <p>Loading planes...</p>;
	}

	return (
		<div className="container">
			<div>
				<h2>{editingPlane ? "Edit Plane Details" : "Upload Plane Details"}</h2>
				<input
					type="text"
					placeholder="Name"
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
				<textarea
					placeholder="Description"
					value={description}
					onChange={(e) => setDescription(e.target.value)}
				/>
				<label>
					Sold:
					<input
						type="checkbox"
						checked={sold}
						onChange={(e) => setSold(e.target.checked)}
					/>
				</label>
				<input
					type="file"
					accept="image/*"
					onChange={(e) => {
						const file = e.target.files?.[0];
						if (file) {
							setImage(file);
						} else {
							alert("No image file selected.");
						}
					}}
				/>
				<input
					type="file"
					accept="application/pdf"
					onChange={(e) => {
						const file = e.target.files?.[0];
						if (file) {
							setPdfDocument(file);
						} else {
							alert("No PDF document selected.");
						}
					}}
				/>
				<div className="button-group">
					<button onClick={handleUpload} disabled={uploading}>
						{uploading ? "Uploading..." : editingPlane ? "Update" : "Upload"}
					</button>
					{editingPlane && (
						<button onClick={resetForm} disabled={uploading}>
							Cancel Update
						</button>
					)}
				</div>
			</div>
			<div>
				<h2 style={{ marginTop: "50px" }}>All Planes</h2>
				<ul>
					{planes.map((plane) => (
						<li key={plane.id}>
							<h3>{plane.name}</h3>
							<p>{plane.description}</p>
							<p>Sold: {plane.sold ? "Yes" : "No"}</p>
							{plane.imageLink && (
								<div>
									<img src={plane.imageLink} alt={plane.name} width="200" />
								</div>
							)}
							{plane.pdfDocument && (
								<div>
									<a
										href={plane.pdfDocument}
										target="_blank"
										rel="noopener noreferrer"
									>
										View PDF Document
									</a>
								</div>
							)}
							<div>
								<button onClick={() => handleDelete(plane)}>Delete</button>
								<button onClick={() => handleMarkAsSold(plane)}>
									Mark as Sold
								</button>
								<button onClick={() => handleEdit(plane)}>Edit</button>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default PlaneUpload;
