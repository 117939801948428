import { motion, useTransform, useScroll } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../Sections/Upload/firebase"; // Adjust the path to your Firebase setup
import about from "../../Assets/titles/SCROLL -.svg";
import inventory from "../../Assets/titles/SERVICESBlack.svg";
import clouds from "../../Assets/clouds.mov";
import planeA from "../../Assets/Iventory/lear.png";
import planeB from "../../Assets/Iventory/lear2.png";
import { Link } from "react-router-dom";

function Inventory() {
	const [planes, setPlanes] = useState([]);
	const targetRef = useRef(null);
	const { scrollYProgress } = useScroll({
		target: targetRef,
	});

	const x = useTransform(scrollYProgress, [0.2, 1], ["1%", "-80%"]);
	const opacity = useTransform(
		scrollYProgress,
		[0, 0.1, 0.9, 1],
		[0, 0.2, 0.2, 0]
	);

	const opacityB = useTransform(
		scrollYProgress,
		[0, 0.1, 0.9, 1],
		[0.2, 1, 1, 0]
	);

	useEffect(() => {
		const fetchPlanes = async () => {
			const planesCollection = collection(db, "planes");
			const planesSnapshot = await getDocs(planesCollection);
			const planesList = planesSnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));
			setPlanes(planesList);
		};

		fetchPlanes();
	}, []);

	return (
		<section className="inventory-container" ref={targetRef}>
			<motion.video autoPlay muted loop style={{ opacity: opacity }}>
				<source src={clouds} />
			</motion.video>

			<motion.div
				style={{ x, opacity: opacityB }}
				className="thumbnails-container"
			>
				<div className="intro-inner-container">
					<div className="intro-inner-container">
						<div className="intro-title-wrapper">
							<img src={inventory} alt={inventory} />
						</div>
						<div className="intro-text-container">
							<p style={{ color: "black" }}>
								Our inventory showcases a meticulously curated selection of
								aircraft, designed to meet the diverse needs and preferences of
								aviation enthusiasts and professionals alike.
							</p>
						</div>
					</div>
					<div className="intro-title-wrapper">
						<img src={about} alt={about} />
					</div>
				</div>

				{planes.length > 0 ? (
					planes.map((plane) => (
						<div key={plane.id} className="inventory-inner-container">
							<img src={plane.imageLink} alt={plane.name} />
							<div className="inventory-title-wrapper">
								<h2>{plane.name}</h2>
								<p>{plane.description}</p>
								{plane.sold && <span className="sold-tag">Sold</span>}
								<a
									className="left-btn-box"
									href={plane.pdfDocument}
									target="_blank"
									rel="noopener noreferrer"
								>
									See more...
								</a>
							</div>
						</div>
					))
				) : (
					<p>No planes available at the moment.</p>
				)}
			</motion.div>
		</section>
	);
}

export default Inventory;
