import React from "react";
import Labs from "../../Assets/Lab.png";
import "./styles.scss";

function Lab() {
  return (
    <div className="Lab-container">
      {/* <div className="lab-img-wrapper">
        <img src={Labs} />
      </div> */}
      <div className="text-clinic-container">
        <h1>
          Our Jetbrothers <span> Guarantee</span> 2024.
        </h1>
        <p>
          We offer clients the assurance that products or services meet the
          highest standards of excellence and performance. It represents a
          company's steadfast commitment to delivering superior value,
          underpinned by rigorous quality control processes and continuous
          improvement practices.
        </p>
      </div>
    </div>
  );
}
export default Lab;
