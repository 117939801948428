import React, { useEffect, useRef, useState } from "react";
import { useTransform, useScroll, motion } from "framer-motion";
import Lenis from "@studio-freight/lenis";
import imgVideob from "../../Assets/planeB.mov";

import "./styles.scss";

function Services() {
	const container = useRef(null);

	const [dimension, setDimension] = useState({ width: 0, height: 0 });
	const { scrollYProgress } = useScroll({
		target: container,
		offset: ["start end", "end start"],
	});

	const { height } = dimension;

	const opacity = useTransform(
		scrollYProgress,
		[0, 0.1, 0.2, 0.8],
		[1, 1, 1, 1]
	);

	const opacityb = useTransform(scrollYProgress, [0, 0.2, 0.5], [0, 0.5, 1]);
	const y = useTransform(
		scrollYProgress,
		[0, 0.9],
		[height * 0.05, height * -0.1]
	);

	const opacityc = useTransform(
		scrollYProgress,
		[0, 0.1, 0.5, 0.9],
		[0, 0, 1, 0]
	);
	const opacityd = useTransform(
		scrollYProgress,
		[0, 0.5, 0.6, 0.7],
		[0, 0.1, 1, 0]
	);
	const opacitye = useTransform(
		scrollYProgress,
		[0, 0.5, 0.6, 0.9],
		[0, 0, 1, 0]
	);

	const opacityVideo = useTransform(
		scrollYProgress,
		[0, 0.3, 0.5, 0.6, 1],
		[0, 0, 0.3, 0.3, 0]
	);

	const yB = useTransform(
		scrollYProgress,
		[0.2, 0.7],
		[height * 0.9, height * -1]
	);

	const yC = useTransform(
		scrollYProgress,
		[0.2, 0.7],
		[height * 2, height * -1]
	);
	const yD = useTransform(
		scrollYProgress,
		[0.2, 0.8],
		[height * 5, height * -1]
	);
	useEffect(() => {
		const lenis = new Lenis();

		const raf = (time) => {
			lenis.raf(time);
			requestAnimationFrame(raf);
		};

		const resize = () => {
			setDimension({ width: window.innerWidth, height: window.innerHeight });
		};

		window.addEventListener("resize", resize);
		requestAnimationFrame(raf);
		resize();

		console.log(scrollYProgress);
		return () => {
			window.removeEventListener("resize", resize);
		};
	}, []);

	return (
		<>
			<motion.div
				className="VrGlasess-container "
				style={{ opacity: opacity }}
				ref={container}
				//  style={{ y }}
			>
				<div className="VrInner-container">
					<motion.video
						playsInline
						autoPlay
						loop
						muted
						disableRemotePlayback
						style={{ opacity: opacityVideo }}
					>
						<source src={imgVideob} type="video/mp4" />
					</motion.video>
					<motion.div
						className="Vr-text-container"
						style={{ opacity: opacityb, translateY: y }}
						ref={container}
					>
						<h1>
							<span>Services</span> That we offer
						</h1>
						<div>
							<h2>Buying, Selling, Managing</h2>
							<p>
								Our Services We offer comprehensive aircraft services including
								sales, acquisitions, and management. Whether you're buying or
								selling, our experts guide you through every step, handling
								negotiations and closing for a seamless transaction. We assess
								your needs, conduct market research, and provide detailed
								inspections to secure the best deals. Our management services
								cover operational management, maintenance, and compliance,
								ensuring your aircraft is always flight-ready.
							</p>
						</div>
					</motion.div>
					<div className="Vr-image-container">
						<motion.div
							style={{ opacity: opacityc, translateY: yB }}
							className="Vr-text-containerb"
						>
							<h1>
								<span>Buying</span> an aircraft
							</h1>
							<div>
								<h2>Expert consultation</h2>
								<p>
									Acquiring a private aircraft represents a substantial
									milestone, embodying more than just luxury travel—it signifies
									an investment in time efficiency, privacy, and personal or
									corporate branding. This intricate process encompasses several
									pivotal steps, commencing with the precise definition of
									individual needs, including travel patterns, range
									requirements, and desired cabin amenities. Selecting the ideal
									model entails aligning considerations of budget and lifestyle
									aspirations. Expert consultation is frequently sought to
									adeptly navigate the intricacies of acquisition, encompassing
									legalities, financing, and compliance with aviation
									regulations.
								</p>
							</div>
						</motion.div>
						<motion.div
							style={{ opacity: opacityd, translateY: yC }}
							className="Vr-text-containerb"
						>
							<h1>
								<span>Selling</span> an aircraft
							</h1>
							<div>
								<h2>Buying, Selling, Managing</h2>
								<p>
									Selling a private aircraft entails a nuanced process that
									integrates market expertise, strategic positioning, and a deep
									comprehension of the aircraft's distinctive selling
									attributes. It initiates with a meticulous evaluation to
									precisely ascertain the market value of the jet, considering
									variables such as its age, maintenance history, upgrades, and
									overall condition.
								</p>
							</div>
						</motion.div>
						<motion.div
							style={{ opacity: opacitye, translateY: yD }}
							className="Vr-text-containerb"
						>
							<h1>
								<span>Managing</span> service
							</h1>
							<div>
								<h2>Buying, Selling, Managing</h2>
								<p>
									Managing a private aircraft encompasses a broad spectrum of
									responsibilities, ensuring operational readiness, safety, and
									compliance while optimizing the ownership experience. This
									involves meticulous oversight of maintenance schedules,
									adherence to stringent aviation safety standards, and the
									coordination of flight operations, including pilot selection,
									flight planning, and regulatory compliance.
								</p>
							</div>
						</motion.div>
					</div>
				</div>
			</motion.div>
		</>
	);
}
export default Services;
