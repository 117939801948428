import React from "react";
import Navbar from "../../Sections/NavBar";
import "../../../src/App.css";
import DigitalTwin from "../../Sections/SlideShow/DigitalTwin";

import PlaneUpload from "../../Sections/Upload";

function Upload() {
	return (
		<div className="App">
			<Navbar />
			<div id="hero" className="Hero-outter-container">
				<PlaneUpload />
			</div>
		</div>
	);
}
export default Upload;
