import { motion, useTransform, useScroll } from "framer-motion";
import { useRef } from "react";
import "./styles.scss";
import about from "../../Assets/titles/about.svg";
import services from "../../Assets/titles/SERVICES.svg";
import inventory from "../../Assets/titles/INVENTORY.svg";
import clouds from "../../Assets/clouds.mov";

function Intro() {
	const targetRef = useRef(null);
	const { scrollYProgress } = useScroll({
		target: targetRef,
	});

	const x = useTransform(scrollYProgress, [0, 1], ["1%", "-80%"]);
	const opacity = useTransform(scrollYProgress, [0, 0.2, 0.7, 1], [0, 1, 1, 0]);
	return (
		<section className="introJet-container" ref={targetRef}>
			<motion.video autoPlay muted loop style={{ opacity: opacity }}>
				<source src={clouds} />
			</motion.video>
			<motion.div style={{ x }} className="thumbnails-container">
				<div className="intro-inner-container">
					<div className="intro-title-wrapper">
						<img src={about} alt={about} />
					</div>
					<div className="intro-text-container">
						<h2>Make your transition</h2>
						<p>
							Jetbrothers specializes in facilitating swift and streamlined
							transitions to your next aircraft, ensuring a seamless experience.
							As a boutique firm, we prioritize personalized service, tailoring
							our approach to each client's unique needs. Our dedication lies in
							comprehensively understanding your mission requirements,
							safeguarding your interests, and expertly guiding you through
							every step of the process.
						</p>
					</div>
				</div>
				<div className="intro-inner-container">
					<div className="intro-title-wrapper">
						<img src={services} alt={services} />
					</div>
					<div className="intro-text-container">
						<h2>What do we offer?</h2>
						<p>
							Jetbrothers provides a range of professional services encompassing
							aircraft sales, acquisitions, and management. Our offerings are
							diverse and comprehensive, designed to cater to various
							requirements within the aviation sector. Each option is
							meticulously crafted to address distinct needs, ensuring tailored
							solutions for our discerning clientele.
						</p>
					</div>
				</div>
				<div className="intro-inner-container">
					<div className="intro-title-wrapper">
						<img src={inventory} alt={inventory} />
					</div>
					<div className="intro-text-container">
						<h2>Curated selection</h2>
						<p>
							Our inventory presents a thoughtfully curated array of aircraft,
							meticulously selected to cater to the varied preferences and
							requirements of both aviation enthusiasts and industry
							professionals. Spanning from sleek and efficient single-engine
							planes ideal for personal travel to robust multi-engine models
							tailored for corporate usage, our collection boasts diversity and
							versatility.
						</p>
					</div>
				</div>
				{/* <div className="intro-inner-container">
          <div className="intro-title-wrapper">
            <img src={about} alt={about} />
          </div>
          <div className="intro-text-container">
            <h2>Make your transition</h2>
            <p>
              Jetbrothers Make your transition into your next aircraft faster
              and easier. Adquisition As a boutique firm, we focus on every
              client as an individual, personalizing our approach to understand
              your mission requirements, protect your interests, and guide you
              through a seamless process.
            </p>
          </div>
        </div> */}
			</motion.div>
		</section>
	);
}

export default Intro;
