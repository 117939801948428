import "./style.scss";
// import Laboratry from "../Assest/laboratry.svg";

import Xlogo from "../../Assets/BottomBar/X-logo.svg";
import Instalogo from "../../Assets/BottomBar/insta.svg";
import Linkdinlogo from "../../Assets/BottomBar/linkdin.svg";

import Circlelogo from "../../Assets/BottomBar/circle.svg";
import { Link } from "react-router-dom";

function Bottombar() {
	return (
		<div className="Bottom-bar-container">
			<div className="first-item-bottom">
				<Link
					className="bootom-btn"
					to="https://www.instagram.com/flyjetbrothers/ "
				>
					<img src={Instalogo} alt="" />
				</Link>

				<Link
					className="bootom-btn"
					to="https://www.instagram.com/flyjetbrothers/ "
				></Link>
			</div>

			<div className="copyright-text">
				Copyright © 2024 flyjetbrothers. All rights reserved.
			</div>
		</div>
	);
}

export default Bottombar;
