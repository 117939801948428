import "./style.scss";
import GneticLogoFooter from "../../Assets/genticlogofooter.svg";
import { Link } from "react-router-dom";

function GeneticFooter() {
	return (
		<div className="Genetic-footer">
			<div className="footer-left-logo">
				<img src={GneticLogoFooter} alt="Genetic Logo" />
			</div>

			<div className="footer-right-container">
				<div className="footer-right-item">
					<p className="address-text">Address</p>
					<div className="text-box-footer-right">
						1100 Lee Wagener Blvd Suite 312 Fort lauderdale FL 33315
					</div>
				</div>

				{/* <div className="footer-right-item">
          <div className="address-text">Company</div>
          <div className="text-box-footer-right">
            <div>Journey</div>
            <div>Career</div>
          </div>
        </div> */}

				<div className="footer-right-item3">
					<p className="address-text">Contact</p>
					<div className="text-box-footer-right">
						<div className="text-box-footer-right">
							<a
								className="hover"
								onClick={() =>
									(window.location.href = "mailto:Sales@flyjetbrothers.com")
								}
							>
								Sales@flyjetbrothers.com
							</a>
							<a
								className="hover"
								onClick={() =>
									(window.location.href = "mailto:Gilbert@flyjetbrothers.com")
								}
							>
								Gilbert@flyjetbrothers.com
							</a>
							<a
								className="hover"
								onClick={() =>
									(window.location.href = "mailto:Roberto@flyjetbrothers.com")
								}
							>
								Roberto@flyjetbrothers.com
							</a>
							<a
								className="hover"
								onClick={() =>
									(window.location.href = "mailto:Sales@flyjetbrothers.com")
								}
							>
								Login
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GeneticFooter;
