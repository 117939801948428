// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyC7kU_Sf7YD5dLqsTs2_YXpabyp_cTlST4",
	authDomain: "jetbrothers-cbdd7.firebaseapp.com",
	projectId: "jetbrothers-cbdd7",
	storageBucket: "jetbrothers-cbdd7.appspot.com",
	messagingSenderId: "631771867429",
	appId: "1:631771867429:web:9f54ae1320cd695d4b52cc",
	measurementId: "G-7SH6X2XRNB",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
