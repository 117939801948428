import React, { useEffect, useRef, useState } from "react";
import { useTransform, useScroll, motion } from "framer-motion";
import Lenis from "@studio-freight/lenis";
import imgVideo from "../../Assets/planeC.mov";
import "./styles.scss";

function About() {
	const container = useRef(null);

	const [dimension, setDimension] = useState({ width: 0, height: 0 });
	const { scrollYProgress } = useScroll({
		target: container,
		offset: ["start end", "end start"],
	});

	const { height } = dimension;

	const opacity = useTransform(scrollYProgress, [0, 0.1, 0.2], [0.5, 1, 1]);
	const opacityVideo = useTransform(
		scrollYProgress,
		[0, 0.3, 0.5, 0.6, 1],
		[0, 0, 0.3, 0.3, 0]
	);

	const opacityb = useTransform(
		scrollYProgress,
		[0, 0.3, 0.5, 0.6],
		[0, 0, 1, 0]
	);
	const opacityC = useTransform(
		scrollYProgress,
		[0, 0.7, 0.8, 1],
		[0, 0, 1, 0]
	);
	const y = useTransform(
		scrollYProgress,
		[0.3, 0.9],
		[height * 0.5, height * -0.5]
	);
	const yB = useTransform(
		scrollYProgress,
		[0.4, 0.9],
		[height * 0.5, height * -0.5]
	);

	useEffect(() => {
		const lenis = new Lenis();

		const raf = (time) => {
			lenis.raf(time);
			requestAnimationFrame(raf);
		};

		const resize = () => {
			setDimension({ width: window.innerWidth, height: window.innerHeight });
		};

		window.addEventListener("resize", resize);
		requestAnimationFrame(raf);
		resize();

		console.log(scrollYProgress);
		return () => {
			window.removeEventListener("resize", resize);
		};
	}, []);

	return (
		<>
			<motion.div
				className="advanceImaging-container"
				style={{ opacity: opacity }}
				ref={container}
				//  style={{ y }}
			>
				<div className="advanceImaging-innerContainer">
					<motion.video
						playsInline
						autoPlay
						loop
						muted
						disableRemotePlayback
						style={{ opacity: opacityVideo }}
					>
						<source src={imgVideo} type="video/mp4" />
					</motion.video>
					<motion.div
						className="advanceImaging-text-container"
						ref={container}
						style={{ opacity: opacityb, translateY: y }}
					>
						<h1>
							<span>Jetbrothers</span> commitment to excellence.
						</h1>
						<div>
							<h2>About</h2>
							<p>
								We stand as a premier private aircraft sales company, renowned
								for our commitment to excellence and our reputation for
								delivering an exclusive array of luxury private planes. Tailored
								to meet the discerning requirements of high net worth
								individuals and corporate clientele, our selection embodies
								sophistication and distinction.
							</p>
						</div>
					</motion.div>
					<motion.div
						className="advanceImaging-text-container"
						ref={container}
						style={{ opacity: opacityC, translateY: yB }}
					>
						<h1>
							<span>Jetbrothers</span> core value
						</h1>
						<div>
							<h2>Integrity</h2>
							<p>
								We uphold the utmost standards of integrity and ethical conduct
								in every transaction and interaction, fostering transparency and
								trustworthiness at all times.
							</p>
							<h2>Exelence</h2>
							<p>
								We are dedicated to providing nothing but the highest caliber of
								aircraft and delivering unparalleled quality services. Our
								commitment to excellence drives us to strive for perfection in
								every facet of our business operations.
							</p>
							<h2>Client-Centric</h2>
							<p>
								We prioritize the individual needs and preferences of our
								clients, offering bespoke services to ensure that each client's
								experience is tailored precisely to their unique requirements.
							</p>
						</div>
					</motion.div>
				</div>
			</motion.div>
		</>
	);
}
export default About;
